<template>
  <div class="login">
    <div class="login-container">
      <div class="login-container-left">
        <div class="login-container-left-img">
          <img src="../../assets/img/login/kl.png" />
          <img src="../../assets/img/login/gy.png" />
        </div>
        <img src="../../assets/img/login/bg2.png" class="login-bg2" />
      </div>
      <div class="login-container-right">
        <div id="zb_login_sub" @click="idLogin.show = !idLogin.show">
          <div v-if="!idLogin.show">
            <img class="zb_login_sub_show" src="../../assets/img/login/zhanghaots.png" />
            <img src="../../assets/img/login/zhanghao.png" />
          </div>
          <div v-if="idLogin.show">
            <img class="zb_login_sub_show" src="../../assets/img/login/saoma.png" />
            <img src="../../assets/img/login/erweima.png" />
          </div>
        </div>
        <p class="p1">
          智掌互联数解舆情
          <span class="span1">®️</span>
        </p>
        <p class="p2">V4.0</p>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-show="!idLogin.show">
          <el-tab-pane name="first">
            <div slot="label" class="tab-pane-label">
              <img class="img" v-if="activeName == 'first'" src="../../assets/img/views/weixin.png" alt />
              <img class="img" v-else src="../../assets/img/views/weixin .png" alt />
              <span class="img-text">微信扫码</span>
            </div>
            <div class="login-sm">
              <div class="login-container-erwm" id="wei-code" v-loading="loading">
                <iframe :src="url" frameborder="0" width="100%" height="100%"></iframe>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="second">
            <div slot="label" class="tab-pane-app">
              <img class="img" v-if="activeName == 'second'" src="../../assets/img/views/iphone.png" alt />
              <img class="img" v-else src="../../assets/img/views/app .png" alt />
              <span class="img-text">App扫码</span>
            </div>
            <div class="login-sm">
              <div class="login-container-erwm" id="wei-code" v-loading="loading">
                <img :src="appUrl" class="app-img" />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div id="zb_login_id" v-show="idLogin.show">
          <el-input @input="inputChange" v-model="idLogin.id" placeholder="请输入账号" maxlength="11" clearable></el-input>
          <el-input placeholder="请输入密码" v-model="idLogin.pwd" maxlength="8" show-password></el-input>
          <div>
            <el-input style="width:50%" v-model="idLogin.yzm" placeholder="请输入验证码" maxlength="4"
              @keyup.enter.native="login"></el-input>
            <img :src="'data:image/jpeg;base64,' + idLogin.img" @click="getYZM" />
          </div>
          <el-button style="width:100%" type="primary" @click="login">登 录</el-button>
        </div>
        <div class="downLoad">
          <el-popover class="popover" trigger="hover" placement="top" width="300" v-model="visible">
            <img style="width: 200px; height: 100%" src="../../assets/img/views/down.png" alt />
            <div style="text-align: right; margin: 0"></div>
            <el-button class="downLoad_button" round slot="reference">下载APP</el-button>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="login-foot">
      康奈网络&nbsp;©️&nbsp;提供技术支持&nbsp;&nbsp;www.yuqing.cn&nbsp;&nbsp;<a
        href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">陕ICP备14007110号-6</a>
    </div>
  </div>
</template>
<script>
import { captcha, system } from "@/api/login/api.js";
import md5 from "js-md5";

export default {
  data() {
    return {
      activeName: "first",
      loading: false,
      time: 4 * 60 * 1000,
      appUrl: "",
      appQRCode: false,
      url: "",
      visible: false,
      idLogin: {
        show: false,
        id: "",
        pwd: "",
        yzm: "",
        img: "",
        uuid: null
      }
    };
  },
  created() {
    let type = this.$route.query.errorMsg;
    this.getYZM();
    if (type === "1") {
      this.$message({
        message: "未关注公众号！",
        type: "warning",
      });
    } else if (type === "2") {
      this.$message({
        message: "账号未设置激活！",
        type: "warning",
      });
    } else if (type === "4") {
      this.$message({
        message: "不是客户系统用户！",
        type: "warning",
      });
    } else if (type === "6") {
      this.$message({
        message: "没有所属机构！",
        type: "warning",
      });
    } else if (type === "7") {
      this.$message({
        message: "您的账号已停用！",
        type: "warning",
      });
      this.$router.push("/login");
    } else if (type === "8") {
      this.$message({
        message: "所属机构已被禁用！",
        type: "warning",
      });
    }
    if (this.$route.query.url) {
      sessionStorage.setItem("bigUrl", this.$route.query.url);
    }
    if (this.$route.query.type) {
      sessionStorage.setItem("bigType", this.$route.query.type);
    }
  },
  methods: {
    //二维码切换
    handleClick() {
      if (this.activeName == "first") {
        this.getWxUrl();
        this.appQRCode = false;
      } else {
        this.appQRCode = "";
        this.getApp();
      }
    },
    // 获取微信二维码
    getWxUrl() {
      this.loading = true;
      this.$http({
        method: "get",
        url: "/api/auth/wxLogin?loginType=customer",
      })
        .then((res) => {
          this.url =
            res.data.split("#wechat_redirect")[0] +
            "&href=https://www.yuqing.cn/WeChartCustomet.css#wechat_redirect";
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取APP二维码
    getApp() {
      this.loading = true;
      let _this = this;
      this.$http({
        method: "get",
        url: "/api/auth/getAppQrcode",
        responseType: "blob",
      })
        .then((res) => {
          this.appQRCode = res.headers.state;
          let reader = new FileReader();
          reader.readAsDataURL(res.data); // 转换为base64，可以直接放
          reader.onload = function () {
            _this.appUrl = reader.result;
          };
          this.loading = false;
          this.pollingQRCode();
        })
        .catch((err) => {
          console.log(err);
        });
      //每 this.time 分钟 获取一次新的二维码
      setTimeout(function () {
        _this.getApp();
      }, _this.time);
    },
    // 轮询监听是否扫码
    pollingQRCode() {
      let _this = this;
      if (this.appQRCode === false) {
        return false;
      }
      if (this.appQRCode != "") {
        this.$http({
          method: "post",
          url: "/api/auth/listen/appLogin",
          data: {
            state: this.appQRCode,
          },
        })
          .then((res) => {
            if (res.data.data && res.data.data.access_token) {
              this.$router.push({
                path: "/WeChat",
                query: {
                  accessToken: res.data.data.access_token,
                },
              });
            } else {
              setTimeout(function () {
                _this.pollingQRCode();
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getYZM() {
      captcha().then(res => {
        if (res.data.code == 200) {
          this.idLogin.img = res.data.img;
          this.idLogin.uuid = res.data.uuid;
        }
      })
    },
    login() {
      if (this.idLogin.id === "") {
        this.$message({
          type: "warning",
          message: "请输入账号！"
        })
        return;
      }
      if (this.idLogin.pwd === "") {
        this.$message({
          type: "warning",
          message: "请输入密码！"
        })
        return;
      }
      if (this.idLogin.yzm === "") {
        this.$message({
          type: "warning",
          message: "请输入验证码！"
        })
        return;
      }
      this.getYZM();
      let data = {
        username: this.idLogin.id,
        password: md5(this.idLogin.pwd),
        code: this.idLogin.yzm,
        uuid: this.idLogin.uuid
      };
      system(data).then(res => {
        if (res.data.code == 200) {
          location.href = res.data.msg;
          if (res.data.msg.indexOf("errorMsg") != -1) {
            location.reload();
          }
        } else if (res.data.msg) {
          this.$message({
            type: "warning",
            message: res.data.msg
          })
        } else {
          this.$message({
            type: "error",
            message: "登录失败！"
          })
        }
      }).catch(() => {
        this.$message({
          type: "error",
          message: "登录失败！"
        })
      })
    },
    inputChange(val) {
      this.idLogin.id = val.replace(/[^\d]/g, "");
    }
  },
  mounted() {
    this.handleClick();
  },
};
</script>

<style>
.login {
  width: 100%;
  height: 100%;
  background: url(../../assets/img/login/loginBg.png) no-repeat;
  background-size: 100% 100%;
  min-height: 700px;
  position: relative;
  display: flex;
  align-items: center;
}

.login-container {
  width: 90%;
  max-width: 1600px;
  min-width: 1200px;
  padding: 0 0 36px 0;
  background: #ffffff;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
}

.login-container-left {
  position: relative;
  float: left;
  width: 60%;
  height: 100%;
}

.login-container-left-img {
  display: flex;
  align-items: center;
  margin-top: 57px;
  margin-left: 76px;
}

.login-container-left span {
  position: absolute;
  left: 52%;
  top: 9.3%;
  color: rgb(59, 60, 61);
}

.login-container-right {
  float: left;
  width: 40%;
  border-left: 1px solid #c7cddf;
  margin-top: 120px;
  height: 600px;
}

.login-container-right p {
  text-align: center;
}

.p1 {
  font-size: 28px;
  color: #3a405b;
  font-weight: bold;
}

.span1 {
  font-size: 24px;
  position: relative;
  top: -5px;
  left: 5px;
}

.p2 {
  font-size: 18px;
  color: #3a405b;
  margin-top: 13px;
  font-weight: bold;
}

.p3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #3a405b;
}

.p3 img {
  margin-right: 10px;
}

.QR-code {
  width: 166px;
  height: 166px;
}

.QR-code-hint {
  display: none;
  position: relative;
  top: -6px;
}

.login-sm {
  height: 320px;
  margin: 40px auto 0;
  width: 318px;
  border: 1px solid #c7cddf;
}

.login-container-erwm {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container-erwm iframe {
  width: 100%;
  height: 100%;
}

.login-sm:hover .QR-code-hint {
  display: block;
}

.login-bg2 {
  margin: 47px 0 0 16px;
  width: 85%;
}

.login-foot {
  width: 100%;
  position: absolute;
  bottom: 23px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.login .el-tabs {
  width: 320px;
  margin: 40px auto 40px;
}

.login .el-tabs__active-bar {
  width: 100px;
}

.login .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

.login .el-tabs__item.is-active {
  color: #3a405b;
}

.login .el-tabs__item {
  font-size: 20px;
  color: #8f9097;
  margin-bottom: 25px;
  padding: 0;
}

.img-text,
.img {
  display: inline-block;
  vertical-align: middle;
}

.img {
  margin-right: 10px;
}

.login .tab-pane-label {
  padding: 0 21px;
}

.login .tab-pane-app {
  padding: 0 21px;
}

.app-img {
  width: 250px;
  height: 250px;
}

.downLoad {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downLoad_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 96px;
  height: 32px;
  background-color: #ebf4ff;
  color: #2e59ec;
  border: none;
}

.downLoad_button:hover {
  background-color: #2d57e8;
  color: #ffffff;
}

.popover img {
  width: 200px;
}

#zb_login_sub {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

#zb_login_sub:hover .zb_login_sub_show {
  display: block;
}

.zb_login_sub_show {
  display: none;
  position: absolute;
  right: 70px;
  top: 10px;
}

#zb_login_id {
  width: 50%;
  margin: 100px auto 100px;
}

#zb_login_id>* {
  margin-top: 30px;
}

#zb_login_id img {
  width: 110px;
  height: 35px;
  vertical-align: bottom;
  margin-left: 30px;
  cursor: pointer;
}
</style>
