import request from '@/api/request.js'
// 这里面存关于用户的请求
export function login (data) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data: data
  })
}
export function getWxUrl () {
    return request({
      url: '/api/auth/wxLogin',
      method: 'get'
    })
  }
  
//获取验证码
export function captcha () {
  return request({
    url: '/api/auth/captcha',
    method: 'get'
  })
}

//账号密码登录
export function system (data) {
  return request({
    url: '/api/auth/passLogin?loginType=customer',
    method: 'post',
    data:data
  })
}